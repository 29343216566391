<template>
  <div class="queue-detail">
    <div class="back-button-container">
      <a
        @click="clearQueue()"
        class="backButton"
        id="back-btn"
        plain
        :class="{ active: false }"
      >
        <!-- <img
						class="flag"
						:src="require(`@/assets/images/flags/${language.code}.png`)"
					/> -->
        <p class="font-medium block notification-title">
          <feather-icon
            icon="ChevronLeftIcon"
            class="inline-block mr-2 back-button-icon"
            svgClasses="w-4 h-7"
          />
          <span class="back-button-span">
            {{
              lang.queues.detail.backToQueuesList[languageSelected]
            }}</span
          >
        </p>
      </a>
    </div>
    <div class="queue-edit">
      <div class="vx-row queues-table" id="queues-table">
        <vs-col vs-w="12">
          <div class="pt-4 pb-base vs-con-loading__container">
            <div v-if="queue.isDefaultQueue" class="defaultQueue">
              <feather-icon  icon="InfoIcon"></feather-icon>
              <p class="text-danger text">
                {{
                  lang.queues.defaultQueue[
                    languageSelected
                  ]
                }}
              </p>
            </div>
            <new-form-group
                :label="
                  lang.queues.detail.name[languageSelected]
                "
              >
            <vs-input
                  class="fill"
                  required
                  id="queue-name"
                  v-model="queue.name"
                  
                  :danger="validations.queue.nameError"
                  :danger-text="
                    lang.botMaker.testGroups.view.errors.name[
                      languageSelected
                    ]
                  "
                />
            </new-form-group>
            <new-form-group
                :label="
                  lang.queues.detail.description[languageSelected]
                "
              >
              <vs-textarea
                v-model="queue.description"
                v-bind:class="{ error: validations.queue.descriptionError }"
                id="queue-description"
              />
              <div class="error-message height-fit-content mb-4">
                <p v-show="validations.queue.descriptionError">
                  {{ lang.botMaker.testGroups.view.errors.description[
                      languageSelected
                    ] }}
                </p>
              </div>
              
            </new-form-group>
            <new-form-group
                :label="
                  lang.queues.detail.channels[languageSelected]
                "
              >
              <multiselect
                v-model="queue.channels"
                :placeholder="
                  lang.botMaker.addBot.intentionTriggerSecondLevel.table
                    .selectChannels[languageSelected]
                "
                tagPlaceholder=""
                :show-labels="false"
                label="name"
                track-by="value"
                :options="PLATFORMS"
                :multiple="true"
                :taggable="true"
                :limit="3"
                v-if="!queue.isDefaultQueue"
                :limit-text="limitText"
              />
            </new-form-group>
            <vs-row class="vx-row">
              <vs-col vs-w="12" class="pr-0">
                <select-users
                  ref="selectUsersRef"
                  :usersSelected="queue.agents"
                  :selectUsers="selectUser"
                />
                <vs-button
                  color="primary"
                  type="filled"
                  icon="book"
                  class="float-right"
                  @click="toggleIsSelectUsersOpen"
                >
                  {{ lang.staffRoles.manageUsers.button[languageSelected] }}
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-table
              pagination
              :data="jsonAgents"
              :max-items="5"
              :search="true"
              :hoverFlat="true"
              :no-data-text="lang.general.noDataText[languageSelected]"
            >
              <template slot="thead">
                <vs-th sortKey="name">{{
                  lang.botMaker.addBot.manageUsers.table.name[languageSelected]
                }}</vs-th>
                <vs-th sortKey="email">{{
                  lang.botMaker.addBot.manageUsers.table.email[languageSelected]
                }}</vs-th>
                <vs-th></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">{{ tr.name }}</vs-td>
                  <vs-td :data="tr.email">{{ tr.email }}</vs-td>
                  <vs-td>
                    <div class="action-buttons">
                      <vs-button
                        type="flat"
                        @click="deleteUser(tr._id)"
                        icon-pack="feather"
                        icon="icon-x"
                        color="warning"
                      ></vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div>
              <vs-col vs-w="12" class="mt-4">
                <vs-input
                  :label="
                    lang.botMaker.addBot.priorities.prompt.intentsLabel[
                      languageSelected
                    ]
                  "
                  v-on:keyup.enter="
                    addToArray('intents', 'queueIntentValue')
                  "
                  v-if="!queue.isDefaultQueue"
                  :placeholder="
                    lang.botMaker.addBot.priorities.prompt.intentsPlaceholder[
                      languageSelected
                    ]
                  "

                  class="mb-2 w-full"
                  v-model="queueIntentValue"
                />
                <vs-chip
                  @click="
                    queue.intents.splice(
                      queue.intents.indexOf(intent),
                      1
                    )
                  "
                  class="mr-2 mt-3"
                  color="primary"
                  v-if="!queue.isDefaultQueue"
                  v-for="(intent, index) in queue.intents"
                  :key="index"
                  closable
                >
                  <vs-avatar color="primary" text="#️" class="text-lg" />{{ intent }}
                </vs-chip>
              </vs-col>

              <vs-col vs-w="12" class="mt-2">
                <vs-input
                  :label="
                    lang.botMaker.addBot.priorities.prompt.tagsLabel[languageSelected]
                  "
                  v-on:keyup.enter="
                    addToArray('tags', 'queueTagValue')
                  "
                  :placeholder="
                    lang.botMaker.addBot.priorities.prompt.tagsPlaceholder[
                      languageSelected
                    ]
                  "
                  v-if="!queue.isDefaultQueue"
                  class="mb-2 w-full"
                  v-model="queueTagValue"
                />
                <vs-chip
                  @click="
                    queue.tags.splice(queue.tags.indexOf(tag), 1)
                  "
                  class="mr-2 mt-3"
                  color="primary"
                  v-for="(tag, index) in queue.tags"
                  :key="index"
                  v-if="!queue.isDefaultQueue"
                  closable
                >
                  <vs-avatar color="primary" text="🏷" class="text-lg" />{{ tag }}
                </vs-chip>
              </vs-col>
            </div>
            <div class="checkbox-div">
              <vs-col vs-w="12" class="mt-2 checkbox-div">
                <vs-checkbox
                  data-test="noDialogDetectedTakeover"
                  color="primary"
                  class="checkbox"
                  v-if="!queue.isDefaultQueue"
                  v-model="queue.noDialogDetectedTakeover"
                >
                  {{
                    lang.queues.detail.noDialogDetectedTakeover[
                      languageSelected
                    ]
                  }}
                </vs-checkbox>
              </vs-col>
            </div>
            <div class="checkbox-div">
              <vs-col vs-w="12" class="mt-2 checkbox-div">
                <vs-checkbox
                  data-test="sentimentAnalysis"
                  v-if="!queue.isDefaultQueue"
                  color="primary"
                  class="checkbox"
                  v-model="queue.sentimentAnalysis"
                >
                  {{
                    lang.queues.detail.sentimentAnalysis[
                      languageSelected
                    ]
                  }}
                </vs-checkbox>
              </vs-col>
            </div>
            <!-- <SelectUsers :usersSelected="queue.agents" /> -->
          </div>
        </vs-col>
      </div>
    </div>
    <div class="footer">
      <vs-button
        color="danger"
        type="flat"
        @click="clearQueue()"
        >{{ lang.botMaker.cancel[languageSelected] }}</vs-button
      >
      <vs-button
        color="primary"
        type="filled"
        @click="saveQueue()"
        data-cy="saveQueue"
      >
        <span>
          {{ lang.queues.detail.saveQueue[languageSelected] }}
        </span>
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import NewFormGroup from '../bot-maker/add-bot/Components/NewFormGroup.vue'
import staffManagementMixin from '@/mixins/staffManagementMixin'
import { PLATFORMS } from '@/constants/constants'
import SelectUsers from '../staffRoles/components/SelectUsers'

export default {
  name: 'queueDetail',
  mixins: [staffManagementMixin],
  components: {
      NewFormGroup,
      Multiselect: () => import('vue-multiselect'),
      SelectUsers
    // KonectaDropdown: () => import('../../../../components/KonectaDropdown.vue'),
    // VsTable: () => import('@/components/vx-table/vsTable'),
    // VsTh: () => import('@/components/vx-table/vsTh'),
    // VsTr: () => import('@/components/vx-table/vsTr'),
    // VsTd: () => import('@/components/vx-table/vsTd')
    // EmptyTable: () => import('../../components/EmptyTable.vue'),
  },
  props: {
  },
  data() {
    return {
      queue: {
        id: null,
        name: '',
        description: '',
        channels: [],
        agents: [],
        intents: [],
        tags: [],
        noDialogDetectedTakeover: false,
        sentimentAnalysis: false,
      },
      agents: [],
      jsonAgents: [],
      queueIntentValue: '',
      queueIntents: [],
      queueTagValue: '',
      queueTags: [],
      validations: {
        queue: {
          nameError: false,
          descriptionError: false
        }
      },
      PLATFORMS,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('queues', ['selectedQueue']),
  },
  methods: {
    ...mapActions('queues', [
      'UNSET_QUEUE',
      'SAVE_QUEUE'
    ]),
    limitText(count) {
      return `+${count}`
    },
    addToArray(array, value) {
      if (
        this[value].trim().length > 0 &&
        this.queue[array].indexOf(this[value].trim()) === -1
      ) {
        this.queue[array].push(this[value].trim())
        this[value] = ''
      }
    },
    async saveQueue(){
      this.$vs.loading()
        const oldQueue = JSON.stringify(this.queue);
      try {
        let error = false;
        if (this.queue.name === '') {
          error = true;
          this.validations.queue.nameError = true;
        } else {
          this.validations.queue.nameError = false;
        }
        if (this.queue.description === '') {
          error = true;
          this.validations.queue.descriptionError = true;
           
        } else {
          this.validations.queue.descriptionError = false;
        }
        if (error) {
          setTimeout(() => {
            this.$vs.loading.close()
          }, 500)
          return;
        }
        const queue = this.queue;
        queue.agents = queue.agents.map(a => {
          return a._id;
        })
        queue.channels = queue.channels.map(a => {
          return a.value;
        })
        await this.SAVE_QUEUE(this.queue);
        this.$vs.notify({
          title: this.lang.queues.detail.savedQueue.success.title[this.languageSelected],
          text: this.lang.queues.detail.savedQueue.success.text[this.languageSelected],
          color: 'success'
      })
      } catch (ex) {
          this.$vs.notify({
            title: this.lang.queues.detail.savedQueue.error.title[this.languageSelected],
            text: this.lang.queues.detail.savedQueue.error.text[this.languageSelected],
            color: 'danger'
          })
          this.queue = JSON.parse(oldQueue);
      }
      this.$vs.loading.close()
    },
    moreGroupOptions() {
      this.groupOptionsPopup = true
    },
    outsideCase: function () {
      this.caseOptionsPopup = false
      this.groupOptionsPopup = false
    },
    toggleIsSelectUsersOpen() {
      this.$refs.selectUsersRef.setOpenValue(true)
    },
    deleteUser(userId) {
      const index = this.queue.agents.findIndex(item => item._id === userId)
      if (index > -1) {
         this.queue.agents.splice(index, 1);
         Object.assign(this.queue.agents, this.queue.agents)
         this.jsonAgents = JSON.parse(JSON.stringify(this.queue.agents))
      }
    },
    selectUser(users) {
      Object.assign(this.queue.agents, this.queue.agents.concat(users.map(item => ({
          ...item,
          _id: item.id
        }))))
        this.jsonAgents = JSON.parse(JSON.stringify(this.queue.agents))
    },
    clearQueue() {
      this.$router.push({
        path: '/queues',
        query: {
        }
      })
      this.UNSET_QUEUE()
    },
  },
  async mounted() {
    console.log('selectedQueue', this.selectedQueue)
    this.queue.name = this.selectedQueue.name;
    this.queue.description = this.selectedQueue.description;
    this.queue.channels = this.selectedQueue.channels.map(c => ({ name: c, value: c }));
    this.queue.agents = this.selectedQueue.agents;
    this.jsonAgents = JSON.parse(JSON.stringify(this.queue.agents));
    this.queue.intents = this.selectedQueue.intents;
    this.queue.tags = this.selectedQueue.tags;
    this.queue.noDialogDetectedTakeover = this.selectedQueue.noDialogDetectedTakeover;
    this.queue.sentimentAnalysis = this.selectedQueue.sentimentAnalysis;
    this.queue.id = this.selectedQueue._id;
    this.queue.isDefaultQueue = this.selectedQueue.isDefaultQueue;
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.queue-detail {
  .checkbox-div{
    margin-top:25px !important;
  }
  .queues-table {
    margin: 30px 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .multiselect {
    width: 50px !important;
    &--disabled {
      .multiselect__select {
        height: 42px;
      }
    }
    .multiselect__content-wrapper {
      font-size: 1rem;
    }
    .multiselect__input {
      font-size: 1rem;
    }
  }
  
  #btn-create-case {
    margin-left: 0px !important;
    margin-right: auto;
  }
  #queue {
    .title {
      margin-bottom: 20px;
    }
  }
  .back-button-container {
    display: inline-block;
  }
  .back-button-icon {
    text-align: top;
    vertical-align: middle;
    margin-right: 0px !important;
  }
  .back-button-span {
    vertical-align: text-bottom;
  }
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    .vs-button {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
.defaultQueue {
  color: rgba(var(--vs-danger), 1) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  .text {
    margin-left: 10px;
  }
}
</style>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}

</style>

<style scoped>

.center {
  margin: auto;
  width: 100%;
}
</style>
