var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"queue-detail"},[_c('div',{staticClass:"back-button-container"},[_c('a',{staticClass:"backButton",class:{ active: false },attrs:{"id":"back-btn","plain":""},on:{"click":function($event){_vm.clearQueue()}}},[_c('p',{staticClass:"font-medium block notification-title"},[_c('feather-icon',{staticClass:"inline-block mr-2 back-button-icon",attrs:{"icon":"ChevronLeftIcon","svgClasses":"w-4 h-7"}}),_vm._v(" "),_c('span',{staticClass:"back-button-span"},[_vm._v("\n            "+_vm._s(_vm.lang.queues.detail.backToQueuesList[_vm.languageSelected]))])],1)])]),_vm._v(" "),_c('div',{staticClass:"queue-edit"},[_c('div',{staticClass:"vx-row queues-table",attrs:{"id":"queues-table"}},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{staticClass:"pt-4 pb-base vs-con-loading__container"},[(_vm.queue.isDefaultQueue)?_c('div',{staticClass:"defaultQueue"},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}}),_vm._v(" "),_c('p',{staticClass:"text-danger text"},[_vm._v("\n                "+_vm._s(_vm.lang.queues.defaultQueue[
                    _vm.languageSelected
                  ])+"\n              ")])],1):_vm._e(),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.queues.detail.name[_vm.languageSelected]}},[_c('vs-input',{staticClass:"fill",attrs:{"required":"","id":"queue-name","danger":_vm.validations.queue.nameError,"danger-text":_vm.lang.botMaker.testGroups.view.errors.name[
                      _vm.languageSelected
                    ]},model:{value:(_vm.queue.name),callback:function ($$v) {_vm.$set(_vm.queue, "name", $$v)},expression:"queue.name"}})],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.queues.detail.description[_vm.languageSelected]}},[_c('vs-textarea',{class:{ error: _vm.validations.queue.descriptionError },attrs:{"id":"queue-description"},model:{value:(_vm.queue.description),callback:function ($$v) {_vm.$set(_vm.queue, "description", $$v)},expression:"queue.description"}}),_vm._v(" "),_c('div',{staticClass:"error-message height-fit-content mb-4"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.validations.queue.descriptionError),expression:"validations.queue.descriptionError"}]},[_vm._v("\n                  "+_vm._s(_vm.lang.botMaker.testGroups.view.errors.description[
                      _vm.languageSelected
                    ])+"\n                ")])])],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.queues.detail.channels[_vm.languageSelected]}},[(!_vm.queue.isDefaultQueue)?_c('multiselect',{attrs:{"placeholder":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table
                    .selectChannels[_vm.languageSelected],"tagPlaceholder":"","show-labels":false,"label":"name","track-by":"value","options":_vm.PLATFORMS,"multiple":true,"taggable":true,"limit":3,"limit-text":_vm.limitText},model:{value:(_vm.queue.channels),callback:function ($$v) {_vm.$set(_vm.queue, "channels", $$v)},expression:"queue.channels"}}):_vm._e()],1),_vm._v(" "),_c('vs-row',{staticClass:"vx-row"},[_c('vs-col',{staticClass:"pr-0",attrs:{"vs-w":"12"}},[_c('select-users',{ref:"selectUsersRef",attrs:{"usersSelected":_vm.queue.agents,"selectUsers":_vm.selectUser}}),_vm._v(" "),_c('vs-button',{staticClass:"float-right",attrs:{"color":"primary","type":"filled","icon":"book"},on:{"click":_vm.toggleIsSelectUsersOpen}},[_vm._v("\n                  "+_vm._s(_vm.lang.staffRoles.manageUsers.button[_vm.languageSelected])+"\n                ")])],1)],1),_vm._v(" "),_c('vs-table',{attrs:{"pagination":"","data":_vm.jsonAgents,"max-items":5,"search":true,"hoverFlat":true,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":tr.name}},[_vm._v(_vm._s(tr.name))]),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.email}},[_vm._v(_vm._s(tr.email))]),_vm._v(" "),_c('vs-td',[_c('div',{staticClass:"action-buttons"},[_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-x","color":"warning"},on:{"click":function($event){_vm.deleteUser(tr._id)}}})],1)])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"name"}},[_vm._v(_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.name[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',{attrs:{"sortKey":"email"}},[_vm._v(_vm._s(_vm.lang.botMaker.addBot.manageUsers.table.email[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th')],1)],2),_vm._v(" "),_c('div',[_c('vs-col',{staticClass:"mt-4",attrs:{"vs-w":"12"}},[(!_vm.queue.isDefaultQueue)?_c('vs-input',{staticClass:"mb-2 w-full",attrs:{"label":_vm.lang.botMaker.addBot.priorities.prompt.intentsLabel[
                      _vm.languageSelected
                    ],"placeholder":_vm.lang.botMaker.addBot.priorities.prompt.intentsPlaceholder[
                      _vm.languageSelected
                    ]},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.addToArray('intents', 'queueIntentValue')}},model:{value:(_vm.queueIntentValue),callback:function ($$v) {_vm.queueIntentValue=$$v},expression:"queueIntentValue"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.queue.intents),function(intent,index){return (!_vm.queue.isDefaultQueue)?_c('vs-chip',{key:index,staticClass:"mr-2 mt-3",attrs:{"color":"primary","closable":""},on:{"click":function($event){_vm.queue.intents.splice(
                      _vm.queue.intents.indexOf(intent),
                      1
                    )}}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"#️"}}),_vm._v(_vm._s(intent)+"\n                ")],1):_vm._e()})],2),_vm._v(" "),_c('vs-col',{staticClass:"mt-2",attrs:{"vs-w":"12"}},[(!_vm.queue.isDefaultQueue)?_c('vs-input',{staticClass:"mb-2 w-full",attrs:{"label":_vm.lang.botMaker.addBot.priorities.prompt.tagsLabel[_vm.languageSelected],"placeholder":_vm.lang.botMaker.addBot.priorities.prompt.tagsPlaceholder[
                      _vm.languageSelected
                    ]},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.addToArray('tags', 'queueTagValue')}},model:{value:(_vm.queueTagValue),callback:function ($$v) {_vm.queueTagValue=$$v},expression:"queueTagValue"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.queue.tags),function(tag,index){return (!_vm.queue.isDefaultQueue)?_c('vs-chip',{key:index,staticClass:"mr-2 mt-3",attrs:{"color":"primary","closable":""},on:{"click":function($event){_vm.queue.tags.splice(_vm.queue.tags.indexOf(tag), 1)}}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"🏷"}}),_vm._v(_vm._s(tag)+"\n                ")],1):_vm._e()})],2)],1),_vm._v(" "),_c('div',{staticClass:"checkbox-div"},[_c('vs-col',{staticClass:"mt-2 checkbox-div",attrs:{"vs-w":"12"}},[(!_vm.queue.isDefaultQueue)?_c('vs-checkbox',{staticClass:"checkbox",attrs:{"data-test":"noDialogDetectedTakeover","color":"primary"},model:{value:(_vm.queue.noDialogDetectedTakeover),callback:function ($$v) {_vm.$set(_vm.queue, "noDialogDetectedTakeover", $$v)},expression:"queue.noDialogDetectedTakeover"}},[_vm._v("\n                  "+_vm._s(_vm.lang.queues.detail.noDialogDetectedTakeover[
                      _vm.languageSelected
                    ])+"\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"checkbox-div"},[_c('vs-col',{staticClass:"mt-2 checkbox-div",attrs:{"vs-w":"12"}},[(!_vm.queue.isDefaultQueue)?_c('vs-checkbox',{staticClass:"checkbox",attrs:{"data-test":"sentimentAnalysis","color":"primary"},model:{value:(_vm.queue.sentimentAnalysis),callback:function ($$v) {_vm.$set(_vm.queue, "sentimentAnalysis", $$v)},expression:"queue.sentimentAnalysis"}},[_vm._v("\n                  "+_vm._s(_vm.lang.queues.detail.sentimentAnalysis[
                      _vm.languageSelected
                    ])+"\n                ")]):_vm._e()],1)],1)],1)])],1)]),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":function($event){_vm.clearQueue()}}},[_vm._v(_vm._s(_vm.lang.botMaker.cancel[_vm.languageSelected]))]),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"filled","data-cy":"saveQueue"},on:{"click":function($event){_vm.saveQueue()}}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.lang.queues.detail.saveQueue[_vm.languageSelected])+"\n        ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }